import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
import { RemoveNullValues } from '../../utils/removeNullableValues';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    paymentMethods: [],
    expenses: [],
    expense: null,
    meta: { currentPage: 0, nextPage: false, prevPage: false, totalPages: 0, totalRecords: 0 },
    expenseConfigs: [],
    oneExpenseConfig: null,
};
// ----------------------------------------------------------------------
export const reservationPaymentMethod = createAsyncThunk('expenses/reservationPaymentMethod', async ({ isPaging }) => {
    try {
        const response = await axios.get(`/reservationPaymentMethods?isPaging=${isPaging}`);
        const { data } = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : err);
    }
});
export const createExpense = createAsyncThunk('expenses/createExpense', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/expenses`, Data);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const createExpenseConfig = createAsyncThunk('expenses/createExpenseConfig', async (Data) => {
    let data;
    try {
        const response = await axios.post(`/expenseConfigs`, RemoveNullValues({
            ...Data,
            description: Data.type.toLowerCase() === 'other' ? Data.description : '',
        }));
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const EditExpenseConfig = createAsyncThunk('expenses/EditExpenseConfig', async ({ id, values }) => {
    let data;
    try {
        const response = await axios.put(`/expenseConfigs/${id}`, RemoveNullValues({
            ...values,
            description: values.type.toLowerCase() === 'other' ? values.description : '',
        }));
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getOneExpenseConfig = createAsyncThunk('expenses/getOneExpenseConfig', async (id) => {
    let data;
    try {
        const response = await axios.get(`/expenseConfigs/${id}`);
        data = await response.data.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message);
    }
});
export const getExpenses = createAsyncThunk('expenses/getExpenses', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterStatus = 'recurring', linked = '', }) => {
    let data;
    const queryParams = {
        search: {
            linked,
            type: filterStatus.toLowerCase(),
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
    };
    const url = createDynamicURL('/expenses', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const unlinkExpense = createAsyncThunk('expenses/unlinkExpense', async (id) => {
    let data;
    try {
        const response = await axios.patch(`/expenses/${id}/unlink`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getExpensesConfig = createAsyncThunk('expenses/getExpensesConfig', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', filterType = '', isPaging = false, }) => {
    let data;
    const queryParams = {
        search: {
            name: filterName,
            type: filterType,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        isPaging,
        sortedBy: order,
    };
    const url = createDynamicURL('/expenseConfigs', queryParams);
    try {
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const getExpenseById = createAsyncThunk('expenses/getExpenseById', async (id) => {
    let data;
    try {
        const response = await axios.get(`/expenses/${id}`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const exportExpense = createAsyncThunk('expenses/exportExpense', async () => {
    let data;
    try {
        const response = await axios.get(`/expenses/export`);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const EditExpense = createAsyncThunk('expenses/EditExpense', async ({ id, values }) => {
    let data;
    try {
        const response = await axios.put(`/expenses/${id}`, values);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteExpense = createAsyncThunk('expenses/deleteExpenses', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/expenses/${id}`);
        data = await response.data;
        if (response.status === 200) {
            if (getParams)
                thunkAPI.dispatch(getExpenses({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteExpenseConfig = createAsyncThunk('expenses/deleteExpenseConfig', async ({ id, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/expenseConfigs/${id}`);
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExpensesConfig({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyExpenses = createAsyncThunk('expenses/deleteManyExpenses', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/expenses/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExpenses({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
export const deleteManyExpensesConfigs = createAsyncThunk('expenses/deleteManyExpensesConfigs', async ({ ids, getParams }, thunkAPI) => {
    let data;
    try {
        const response = await axios.delete(`/expenseConfigs/many`, { data: { ids } });
        data = await response.data;
        if (response.status === 200) {
            thunkAPI.dispatch(getExpensesConfig({ ...getParams }));
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'expense',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createExpense.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(createExpense.fulfilled, (state, action) => {
            state.status = 'succeeded';
        })
            .addCase(createExpense.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getExpenseById.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpenseById.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expense = action.payload.data;
        })
            .addCase(getExpenseById.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getExpenses.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpenses.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expenses = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getExpenses.rejected, (state, action) => {
            state.status = 'failed';
        })
            //-----
            .addCase(getExpensesConfig.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getExpensesConfig.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.expenseConfigs = action.payload.data;
            state.meta = action.payload.meta;
        })
            .addCase(getExpensesConfig.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(reservationPaymentMethod.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(reservationPaymentMethod.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.paymentMethods = action.payload;
        })
            .addCase(reservationPaymentMethod.rejected, (state, action) => {
            state.status = 'failed';
        })
            .addCase(getOneExpenseConfig.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getOneExpenseConfig.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.oneExpenseConfig = action.payload;
        })
            .addCase(getOneExpenseConfig.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
